import React from 'react';
import './OrdersComponent.css'; // Предполагается, что стили будут в этом файле
import { useNavigate } from 'react-router-dom';

const OrdersComponent = () => {
    const navigate = useNavigate();
  // Данные заказов (пример), вы можете заменить их на динамические данные
  const orders = [
    { id: 1, date: '02.03.2024', contract: '57419923-ОИ2', address: 'г. Астана, Кошкарбаева 23', deliveryDate: '05.03.2024', status: 'в работе', payment: true, sum: '1 234 678' },
    { id: 2, date: '05.03.2024', contract: '63902118-ОИ2', address: 'г. Костанай, Абая 45', deliveryDate: '08.03.2024', status: 'доставляется', payment: false, sum: '968 234' },
    // ... Другие заказы
  ];
  const handleAddOrders = () => {
    navigate('/OrdersComponent/OrderCard'); // Путь должен совпадать с маршрутом в вашем роутере
  };
  // Функция для отображения цвета статуса
  const getStatusClassName = (status) => {
    switch (status) {
      case 'в работе':
        return 'status-in-progress';
      case 'доставляется':
        return 'status-delivering';
      case 'в пути':
        return 'status-en-route';
      case 'получен':
        return 'status-received';
      default:
        return '';
    }
  };

  return (
    <div className="orders-container">
      <h1>Заявки</h1>
      <table className="orders-table">
        <thead>
          <tr>
            <th>№</th>
            <th>Дата</th>
            <th>Договор</th>
            <th>Адрес</th>
            <th>Дата доставки</th>
            <th>Статус заказа</th>
            <th>Оплата</th>
            <th>Сумма</th>
            <th>Действия</th>
          </tr>
        </thead>
        <tbody>
          {orders.map(order => (
            <tr key={order.id}>
              <td>{order.id}</td>
              <td>{order.date}</td>
              <td>{order.contract}</td>
              <td>{order.address}</td>
              <td>{order.deliveryDate}</td>
              <td className={getStatusClassName(order.status)}>{order.status}</td>
              <td>{order.payment ? '✓' : '✕'}</td>
              <td>{order.sum}</td>
              <td>⚙️</td> {/* Иконка настроек, вы можете заменить ее на реальную иконку */}
            </tr>
          ))}
        </tbody>
      </table>
      <button className="add-order-button"onClick={handleAddOrders}>Добавить</button>
    </div>
  );
};

export default OrdersComponent;
