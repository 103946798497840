import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Orders.css'; // Убедитесь, что создали соответствующий CSS файл

const Orders = () => {
  const navigate = useNavigate();

  // Предполагаем, что orders - это массив объектов, каждый из которых представляет заказ
  const [orders, setOrders] = useState([
    {
      id: 1,
      date: '02.03.2024',
      client: 'ПТП "Казводохоз"',
      contract: '57419923-01V2',
      address: 'г. Астана, Кошкарбаева 23',
      deliveryDate: '05.03.2024',
      status: 'в работе',
      payment: 'безналичный',
      amount: '1 234 678',
    },
    {
      id: 2,
      date: '05.03.2024',
      client: 'ГУ "Управление внутренней политики"',
      contract: '63902118-01V2',
      address: 'г. Костанай, Абая 45',
      deliveryDate: '08.03.2024',
      status: 'доставляется',
      payment: 'безналичный',
      amount: '968 234',
    },
    {
      id: 3,
      date: '02.03.2024',
      client: 'ЖКХ "Казводохоз"',
      contract: '57419923-01V2',
      address: 'г. Астана, Конаева 23',
      deliveryDate: '05.03.2024',
      status: 'получен',
      payment: 'безналичный',
      amount: '1 234 678',
    },
    {
      id: 4,
      date: '05.03.2024',
      client: 'ТОО "Управление внутренней политики"',
      contract: '63902118-01V2',
      address: 'г. Костанай,комсомольская 22',
      deliveryDate: '08.03.2021',
      status: 'в пути',
      payment: 'безналичный',
      amount: '968 234',
    },
    // Данные заказов, которые обычно вы получаете из API
  ]);

  // Функция для перехода к странице деталей заказа
  const handleRowClick = (orderId) => {
    navigate(`/orders/${orderId}`);
  };

  // Функция для рендеринга строки таблицы
  const renderRow = (order) => (
    <tr key={order.id} onClick={() => handleRowClick(order.id)}>
      <td>{order.date}</td>
      <td>{order.client}</td>
      <td>{order.contract}</td>
      <td>{order.address}</td>
      <td>{order.deliveryDate}</td>
      <td>{renderStatus(order.status)}</td>
      <td>{order.payment}</td>
      <td>{order.amount}</td>
    </tr>
  );

  // Функция для рендеринга статуса заказа
  const renderStatus = (status) => {
    const statusClasses = {
      'в работе': 'status-in-progress',
      'доставляется': 'status-delivering',
      'в пути': 'status-en-route',
      'получен': 'status-received',
    };

    return <span className={`status ${statusClasses[status]}`}>{status}</span>;
  };

  return (
    <div className="orders-container">
      <div className="filters">
        <button className="filter-button">В работе</button>
        <button className="filter-button">доставляется</button>
        <button className="filter-button">в пути</button>
        <button className="filter-button">получен</button>
        {/* Дополнительные кнопки фильтра */}
      </div>
      <table className="orders-table">
        <thead>
          <tr>
            <th>Дата</th>
            <th>Клиент</th>
            <th>Договор</th>
            <th>Адрес</th>
            <th>Дата доставки</th>
            <th>Статус заказа</th>
            <th>Оплата</th>
            <th>Сумма</th>
          </tr>
        </thead>
        <tbody>
          {orders.map(renderRow)}
        </tbody>
      </table>
    </div>
  );
};

export default Orders;
