import React, { useState } from 'react';
import './DocumentTypes.css';

const DocumentTypes = () => {
  const [documentTypes, setDocumentTypes] = useState([
    'Сертификаты',
    'Наши документы',
    'Вет справка',
  ]);
  const [selectedTypeIndex, setSelectedTypeIndex] = useState(null);

  const handleAddClick = () => {
    // Логика для добавления нового типа документа
  };

  const handleDeleteClick = () => {
    if (selectedTypeIndex !== null) {
      setDocumentTypes(documentTypes.filter((_, index) => index !== selectedTypeIndex));
      setSelectedTypeIndex(null); // Сбросить выбранный тип документа
    }
  };

  const handleTypeClick = (index) => {
    setSelectedTypeIndex(index);
  };

  return (
    <div className="document-types-container">
      <div className="actions">
        <button onClick={handleAddClick}>Добавить</button>
        <button onClick={handleDeleteClick} disabled={selectedTypeIndex === null}>Удалить</button>
      </div>
      <ul className="types-list">
        {documentTypes.map((type, index) => (
          <li
            key={index}
            className={index === selectedTypeIndex ? 'selected' : ''}
            onClick={() => handleTypeClick(index)}
          >
            {type}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DocumentTypes;
