import React from 'react';
import './AccountProfile.css'
function AccountProfile() {
  const user = {
    name: 'Иван Иванов',
    email: 'ivan@example.com',
    phone: '+7 (123) 456-78-90',
    address: 'г. Москва, ул. Пушкина, д. 1',
    avatarUrl: 'https://via.placeholder.com/150',
    orders: [
      { id: 1, date: '2024-03-15', total: 1000, status: 'Доставлено' },
      { id: 2, date: '2024-03-20', total: 2000, status: 'В обработке' }
    ]
  };

  return (
    <div className="account-profile">
      <img src={user.avatarUrl} alt="Аватар" className="account-profile__avatar" />
      <div className="account-profile__info">
        <h2 className="account-profile__name">{user.name}</h2>
        <p className="account-profile__email">{user.email}</p>
        <p className="account-profile__phone">{user.phone}</p>
        <p className="account-profile__address">{user.address}</p>
      </div>
      <div className="account-profile__orders">
        <h3>Мои заказы</h3>
        <ul>
          {user.orders.map(order => (
            <li key={order.id}>
              Заказ #{order.id} от {order.date} на сумму {order.total} руб. - {order.status}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default AccountProfile;
