import React, { useState } from 'react';
import './OrderCard.css'; // Здесь будут стили для карточки

const OrderCard = () => {
  const [order, setOrder] = useState({
    client: 'РТП "Каздорснаб"',
    orderNumber: '57419923-01w2',
    deliveryDate: '05.03.2024',
    driver: 'Иванов И.П.',
    vehicle: 'Газель',
    address: 'Акмолинская область, г.Кокшетау, ул. И. Алтынсарина 78',
    selfPickup: false, // добавляем поле для самовывоза
    products: [], // предполагаем, что это массив объектов с полями name, quantity, unit, price, sum
    comments: '',
    // другие поля заявки...
  });

  // Функция для переключения состояния самовывоза
  const toggleSelfPickup = () => {
    setOrder({ ...order, selfPickup: !order.selfPickup });
  };
  return (
    <div className="order-card">
      <header>
        <h1>Заявка № {order.orderNumber} от {order.deliveryDate}</h1>
        <div className="actions">
          <button>Действия</button>
          
          <button>Оформить возврат</button>
        </div>
      </header>
      <section className="order-details">
        <div className="field">
          <label>Клиент:</label>
          <input type="text" value={order.client} readOnly />
        </div>
        <div className="field">
          <label>Адрес доставки:</label>
          <input type="text" value={order.address} readOnly />
        </div>
        <div className="field">
          <label>Дата доставки:</label>
          <input type="text" value={order.deliveryDate} readOnly />
        </div>
       
     
        <div className="field">
          <label>Самовывоз:</label>
          <label className="checkbox-container">
            Да
            <input
              type="checkbox"
              checked={order.selfPickup}
              onChange={toggleSelfPickup}
            />
            <span className="checkmark"></span>
          </label>
          <label className="checkbox-container">
            Нет
            <input
              type="checkbox"
              checked={!order.selfPickup}
              onChange={toggleSelfPickup}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="field">
          <label>Машина:</label>
          <input type="text" value={order.vehicle} readOnly />
        </div>
        <div className="field">
          <label>Водитель:</label>
          <input type="text" value={order.driver} readOnly />
        </div>
        {/* Поля для товаров и их количества */}
        {/* Кнопки и другие элементы управления */}
      </section>
      <footer>
        <button>Отмена</button>
        <button>Сохранить</button>
      </footer>
    </div>
  );
};

export default OrderCard;
