import React, { useState } from 'react';

const ProductComponent = () => {
  const [products, setProducts] = useState(['Мясо птицы', 'Говядина', 'Баранина']);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleAdd = () => {
    // Логика добавления нового товара
    const newProduct = prompt('Введите название нового товара:');
    if (newProduct) {
      setProducts([...products, newProduct]);
    }
  };

  const handleDelete = () => {
    // Логика удаления выбранного товара
    if (selectedProduct !== null) {
      setProducts(products.filter((_, index) => index !== selectedProduct));
      setSelectedProduct(null); // Сбрасываем выбранный товар
    } else {
      alert('Выберите товар для удаления.');
    }
  };

  const handleSelectProduct = (index) => {
    setSelectedProduct(index);
  };

  return (
    <div>
      <div style={{ marginBottom: '20px' }}>
        <h1>Товар</h1>
        <button onClick={handleAdd}>Добавить</button>
        <button onClick={handleDelete}>Удалить</button>
      </div>
      <div>
        <h2>Наименование</h2>
        <ul>
          {products.map((product, index) => (
            <li
              key={index}
              onClick={() => handleSelectProduct(index)}
              style={{
                background: selectedProduct === index ? 'lightgray' : 'transparent',
                cursor: 'pointer'
              }}
            >
              {product}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ProductComponent;
