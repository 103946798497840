import React, { useState } from 'react';
import './OrganizationProfile.css';

const OrganizationProfile = () => {
  const [organization, setOrganization] = useState({
    shortName: '',
    fullName: '',
    bin: '',
    kbe: '',
    accountNumber: '',
    bankName: '',
    address: '',
    phone: '',
    director: '',
    email: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setOrganization({ ...organization, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(organization);
    // Отправка данных на сервер или другая логика обработки формы
  };

  return (
    <div className="organization-profile">
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <label>Наименование:</label>
          <input
            type="text"
            name="shortName"
            value={organization.shortName}
            onChange={handleInputChange}
          />
          <label>Полное наименование:</label>
          <input
            type="text"
            name="fullName"
            value={organization.fullName}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-row">
          <label>БИН:</label>
          <input
            type="text"
            name="bin"
            value={organization.bin}
            onChange={handleInputChange}
          />
          <label>КБЕ:</label>
          <input
            type="text"
            name="kbe"
            value={organization.kbe}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-row">
          <label>Расчетный счет:</label>
          <input
            type="text"
            name="accountNumber"
            value={organization.accountNumber}
            onChange={handleInputChange}
          />
          <label>Банк:</label>
          <input
            type="text"
            name="bankName"
            value={organization.bankName}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-row">
          <label>Адрес:</label>
          <input
            type="text"
            name="address"
            value={organization.address}
            onChange={handleInputChange}
          />
          <label>Телефон:</label>
          <input
            type="text"
            name="phone"
            value={organization.phone}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-row">
          <label>Директор:</label>
          <input
            type="text"
            name="director"
            value={organization.director}
            onChange={handleInputChange}
          />
          <label>E-mail:</label>
          <input
            type="email"
            name="email"
            value={organization.email}
            onChange={handleInputChange}
          />
        </div>
        <button type="submit">Сохранить</button>
      </form>
    </div>
  );
};

export default OrganizationProfile;
