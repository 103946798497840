import React, { useState } from 'react';
import './Users.css';
import { useNavigate } from 'react-router-dom';
const Users = () => {
  const [users, setUsers] = useState([
    { login: 'завсклад', name: 'Иванов И.П.', registrationDate: '07.02.2024' },
    { login: 'operator', name: 'Козлова А.В.', registrationDate: '07.02.2024' },
    { login: 'технолог', name: 'Сергеев В.А.', registrationDate: '07.02.2024' }
  ]);
  const [selectedUserIndex, setSelectedUserIndex] = useState(null);
  const navigate = useNavigate();
  const handleAddUser = () => {navigate('/Users/AddUserForm');
    // Логика добавления пользователя
  };

  const handleDeleteUser = () => {
    if (selectedUserIndex !== null) {
      const updatedUsers = users.filter((_, index) => index !== selectedUserIndex);
      setUsers(updatedUsers);
      setSelectedUserIndex(null); // Сбросить выбранного пользователя
    }
  };

  const handleUserSelect = (index) => {
    setSelectedUserIndex(index);
  };

  return (
    <div className="users-container">
      <div className="search-bar">
        <input placeholder="Поиск по имени" />
        <input placeholder="Поиск по логину" />
      </div>
      <div className="buttons">
        <button onClick={handleAddUser}>Добавить</button>
        <button onClick={handleDeleteUser}>Удалить</button>
      </div>
      <table className="users-table">
        <thead>
          <tr>
            <th>Логин</th>
            <th>Имя</th>
            <th>Дата регистрации</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr
              key={index}
              onClick={() => handleUserSelect(index)}
              className={selectedUserIndex === index ? 'selected' : ''}
            >
              <td>{user.login}</td>
              <td>{user.name}</td>
              <td>{user.registrationDate}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Users;
