import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './components/Header&SIdebar/header';
import Sidebar from './components/Header&SIdebar/sidebar';
import Workspace from './components/Header&SIdebar/workspace';
import './App.css'; // Главный CSS файл вашего приложения
import Orders from './components/Header&SIdebar/orders';
import LoginPage from './components/Header&SIdebar/loginpage'
import OrderPage from './components/Header&SIdebar/orderpage';
import DriversComponent from './components/Reference.books/DriversComponent';
import OrganizationProfile from './components/Reference.books/OrganizationProfile';
import Invoice from './components/Header&SIdebar/invoice';
import ClientsList from './components/Header&SIdebar/ClientsList';
import AddClientModal from './components/Header&SIdebar/AddClientModal';
import Vehicles from './components/Reference.books/Vehicles';
import ContractsList from './components/Reference.books/ContractsList';
import AddContract from './components/Reference.books/AddContract';
import Chat from './components/Header&SIdebar/Chat';
import Users from './components/Reference.books/Users';
import AddUserForm from './components/Reference.books/AddUserForm';
import DocumentTypes from './components/Reference.books/DocumentTypes';
import ProductComponent from './components/Reference.books/ProductComponent';
import DeliveryAreasComponent from './components/Reference.books/DeliveryAreasComponent';
import OrdersComponent from './components/Header&SIdebar/OrdersComponent';
import ClientDetails from './components/Header&SIdebar/ClientDetails';
import OrderCard from './components/Header&SIdebar/OrderCard';
import AccountProfile from './components/Profile/AccountProfile';

const App = () => {
  const [activeItem, setActiveItem] = useState('Заказы');

  return (
    <Router>
      <div className="app-container">
        <Header activeItem={activeItem} />
        <Sidebar setActiveItem={setActiveItem} />
        <Workspace>
          <Routes>
            <Route path="/orders" element={<Orders />} />
            <Route path="/loginpage" element={<LoginPage />} />
            <Route path="/orders/:id" element={<OrderPage />} />
            <Route path="/DriversComponent" element={<DriversComponent/>}></Route>
            <Route path="/OrganizationProfile" element={<OrganizationProfile />} />
            <Route path="/orders/:id/Invoice" element={<Invoice />} />
            <Route path="/ClientsList" element={<ClientsList />} />
            <Route path="/ClientsList/AddClientModal" element={<AddClientModal />} />
            <Route path="/Vehicles" element={<Vehicles/>} />
            <Route path="/ContractsList" element={<ContractsList/>} />
            <Route path="/ContractsList/AddContract" element={<AddContract/>} />
            <Route path="/Chat" element={<Chat/>} />
            <Route path="/Users" element={<Users/>} />
            <Route path="/Users/AddUserForm" element={<AddUserForm/>} />
            <Route path="/DocumentTypes" element={<DocumentTypes/>} />
            <Route path="/ProductComponent" element={<ProductComponent/>} />
            <Route path="/DeliveryAreasComponent" element={<DeliveryAreasComponent/>} />
            <Route path="/OrdersComponent" element={<OrdersComponent/>} />
            <Route path="/ClientsList/ClientDetails" element={<ClientDetails/>} />
            <Route path="/OrdersComponent/OrderCard" element={<OrderCard/>} />
            <Route path="/AccountProfile" element={<AccountProfile/>} />
            {/* Можно добавить другие маршруты для отображения разных компонентов в Workspace */}
          </Routes>
        </Workspace>
      </div>
    </Router>
  );
};

export default App;
