import React, { useState } from 'react';
import './AddUserForm.css';

const AddUserForm = () => {
  const [userDetails, setUserDetails] = useState({
    activated: false,
    login: '',
    name: '',
    password: '',
    confirmPassword: '',
    roles: {
      admin: false,
      driver: false,
      operator: false,
      clientLegal: false,
      clientIndividual: false,
    },
  });

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (type === 'checkbox') {
      if (name === 'activated') {
        setUserDetails({ ...userDetails, [name]: checked });
      } else {
        setUserDetails({
          ...userDetails,
          roles: { ...userDetails.roles, [name]: checked }
        });
      }
    } else {
      setUserDetails({ ...userDetails, [name]: value });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Логика отправки данных формы
  };

  return (
    <form className="add-user-form" onSubmit={handleSubmit}>
      <label>
        Активирован:
        <input
          name="activated"
          type="checkbox"
          checked={userDetails.activated}
          onChange={handleInputChange}
        />
      </label>

      <label>
        Логин:
        <input
          name="login"
          type="text"
          value={userDetails.login}
          onChange={handleInputChange}
        />
      </label>

      <label>
        Имя:
        <input
          name="name"
          type="text"
          value={userDetails.name}
          onChange={handleInputChange}
        />
      </label>

      <label>
        Пароль:
        <input
          name="password"
          type="password"
          value={userDetails.password}
          onChange={handleInputChange}
        />
      </label>

      <label>
        Повторите пароль:
        <input
          name="confirmPassword"
          type="password"
          value={userDetails.confirmPassword}
          onChange={handleInputChange}
        />
      </label>

      <fieldset>
        <legend>Группа пользователей:</legend>
        <label>
          Администратор
          <input
            name="admin"
            type="checkbox"
            checked={userDetails.roles.admin}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Водитель
          <input
            name="driver"
            type="checkbox"
            checked={userDetails.roles.driver}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Оператор
          <input
            name="operator"
            type="checkbox"
            checked={userDetails.roles.operator}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Клиент (юридическое лицо)
          <input
            name="clientLegal"
            type="checkbox"
            checked={userDetails.roles.clientLegal}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Клиент (физическое лицо)
          <input
            name="clientIndividual"
            type="checkbox"
            checked={userDetails.roles.clientIndividual}
            onChange={handleInputChange}
          />
        </label>
      </fieldset>

      <button type="submit">Добавить</button>
    </form>
  );
};

export default AddUserForm;
