import React from 'react';
import './ClientDetails.css'; // Предполагается, что стили будут в этом файле

const ClientDetails = () => {
  return (
    <div className="client-details">
      <div className="header">
        <h1>Название клиента</h1>
        <div className="id">
          <span>БИН:</span>
          <span>96015965322</span>
        </div>
        <div className="color"></div>
      </div>
      <div className="full-name">
        <span>Полное наименование:</span>
        <p>Описание полного наименования клиента...</p>
      </div>
      <div className="contracts">
        <h2>Договора</h2>
        <div className="contract-actions">
          <button>Добавить</button>
          <button>Удалить</button>
          <button>Установить основным</button>
        </div>
        <div className="contract-list">
          {/* Договора можно будет выводить здесь */}
          <div className="contract-item">
            <span>Номер:</span>
            <span>Дата:</span>
          </div>
          {/* Пример вывода договора */}
          <div className="contract-item">
            <span>63871502-01/2</span>
            <span>07.02.2024</span>
          </div>
          {/* Ваши договора */}
          {/* ... */}
        </div>
      </div>
    </div>
  );
};

export default ClientDetails;
