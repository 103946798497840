import React from 'react';
import './Header.css'; // Предполагается, что стили будут в этом файле
import { Link } from 'react-router-dom';

const Header = ({ activeItem }) => {
  return (
    <header className="header">
      <div className="header__logo">ЦРМ "МВ4"</div>
      <div className="header__title">{activeItem}</div>
      <div className="header__user-info">
        <Link to="/AccountProfile" className="user-info__profile-link">Панов А.</Link>
        <button className="user-info__logout">
          {/* Здесь может быть иконка выхода */}
        </button>
      </div>
    </header>
  );
};
export default Header;
