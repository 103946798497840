import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './orderpage.css';

const OrderPage = () => {
  const { id } = useParams();
  const [order, setOrder] = useState({
    id: '',
    client: '',
    contract: '',
    address: '',
    deliveryDate: '',
    vehicle: '',
    driver: '',
    status: '',
    total: '',
    items: [],
    comment: '',
  });
  

  useEffect(() => {
    // Запрос к API для получения информации о заказе по ID
    // Для примера заполним данными напрямую
    setOrder({
      id,
      client: 'ООО "Клиент"',
      contract: '57419923-01Z',
      address: 'Адрес доставки',
      deliveryDate: '05.03.2024',
      vehicle: 'Газель',
      driver: 'Иванов И.П.',
      status: 'в работе',
      total: '1 234 678',
      items: [
        { name: 'Мясо птицы', quantity: 123, unit: 'кг', price: 456, sum: '1 233 323' },
        // Другие товары...
      ],
      comment: '',
    });
  }, [id]);

  const handleCancel = () => {
    // Обработка нажатия на "Отмена"
  };

  const handleSave = () => {
    // Обработка нажатия на "Сохранить"
  };
  const navigate = useNavigate();
  const handleAddContract = () => { navigate('/orders/:id/Invoice');}

  return (
    <div className="order-page-container">
      <div className="order-header">
  <h1>Заказ №{order.id}</h1>
  <div>
    <span className="order-status">Статус: {order.status}</span>
    <button className="create-invoice-btn"onClick={handleAddContract}>Создать накладную</button>
  </div>
</div>
      <div className="order-details">
        <p>Клиент: {order.client}</p>
        <p>Договор: {order.contract}</p>
        <p>Адрес: {order.address}</p>
        <div className="delivery-info">
          <p>Дата доставки: {order.deliveryDate}</p>
          <p>Машина: {order.vehicle}</p>
          <p>Водитель: {order.driver}</p>
        </div>
      </div>
      <table className="order-items">
        <thead>
          <tr>
            <th>Наименование</th>
            <th>Кол-во</th>
            <th>Ед. изм</th>
            <th>Цена</th>
            <th>Сумма</th>
          </tr>
        </thead>
        <tbody>
          {order.items.map(item => (
            <tr key={item.name}>
              <td>{item.name}</td>
              <td>{item.quantity}</td>
              <td>{item.unit}</td>
              <td>{item.price}</td>
              <td>{item.sum}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="order-total">
        <p>Итого: {order.total}</p>
      </div>
      <textarea
        className="order-comment"
        value={order.comment}
        onChange={e => setOrder({ ...order, comment: e.target.value })}
        placeholder="Комментарий..."
      />
      <div className="order-actions">
        <button onClick={handleCancel}>Отмена</button>
        <button onClick={handleSave}>Сохранить</button>
      </div>
    </div>
  );
};

export default OrderPage;
