import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ClientsList.css';

const ClientsList = () => {
  const [clients] = useState([
    { name: 'РГП "Казводхоз"', bin: '110841007053', contract: '43', phone: '+7 772 624 27 88', email: 'rgp_tarvod@mail.ru' },
    { name: 'ГУ "Управление внутренней политики"', bin: '150840013763', contract: '46', phone: '+7 705 234 12 38', email: 'uprav@mail.ru' },
    // ... другие клиенты
  ]);
  const navigate = useNavigate();

  // Метод для обработки добавления нового клиента
  const handleAddClient = () => {
    navigate('/ClientsList/AddClientModal'); // Путь должен совпадать с маршрутом в вашем роутере
  };

  // Здесь могут быть методы для обработки поиска клиента и других действий

  return (
    <div className="clients-list-container">
      <div className="clients-list-header">
        <div className="search-bar">
          <input type="text" placeholder="Поиск по наименованию" />
          <input type="text" placeholder="БИН" />
          <input type="text" placeholder="№ договора" />
        </div>
        <button className="add-client-btn" onClick={handleAddClient}>
          Добавить клиента
        </button>
      </div>
      <table className="clients-table">
        <thead>
          <tr>
            <th>Наименование</th>
            <th>БИН</th>
            <th>№ договора</th>
            <th>Телефон</th>
            <th>E-Mail</th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client, index) => (
            <tr key={index}>
              <td>{client.name}</td>
              <td>{client.bin}</td>
              <td>{client.contract}</td>
              <td>{client.phone}</td>
              <td>{client.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ClientsList;
